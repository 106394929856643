<template>
  <v-container fluid class="px-6 py-6" v-if="lesson">
    <v-row justify="end">
      <v-col lg="8" cols="12" md="8" v-if="colIgnore">
        <v-card class="border-radius-xl card-shadow">
          <div class="d-flex flex-row pb-0 px-4 py-4">
            <h6
              class="text-h5 my-auto text-typo font-weight-bold"
              style="white-space: nowrap"
            >
              {{ lesson.courseInfo.COURSE_NAME }},
              <span class="blue--text"> {{ _getClassName() }}</span>
            </h6>
            <v-spacer></v-spacer>
            <span class="text-typo">
              Зааx xичээлийн цаг:
              <span class="blue--text">{{
                lesson.courseInfo.COURSE_CONTACT_HOURS
              }}</span></span
            >
          </div>
          <div class="px-4 py-4 mt-2">
            <TopicTable
              :path="path + '/topics'"
              :headerNames="['Сэдвийн нэр', 'Үзэлт', 'Огноо']"
              :addButtonText="'Сэдэв'"
            >
            </TopicTable>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" md="4" cols="12">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <div class="d-flex flex-column">
              <v-row>
                <v-col cols="10">
                  <v-row justify="space-between">
                    <v-col cols="12" class="pb-0">
                      <strong
                        >Сурагчийн тоо:
                        <span v-if="students" class="blue--text">{{
                          students.length
                        }}</span></strong
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-progress-linear
                  v-if="loadingStudents"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </v-row>
            </div>
          </div>
          <v-sheet
            max-height="600"
            min-height="400"
            style="overflow-y: scroll"
            class="mt-4"
          >
            <v-tabs v-model="selectedTab">
              <!-- <v-tab>Групп</v-tab> -->
              <v-tab>Сурагчид</v-tab>
              <!-- <v-tab>Сэдвүүд</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="selectedTab">
              <!-- <v-tab-item>
                <v-simple-table
                  v-if="groupStudents && groupStudents.length > 0"
                >
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(student, i) in groupStudents"
                        :key="'student-' + student.id + '-' + i"
                        style="cursor: pointer"
                      >
                        <td
                          :class="{
                            'border-bottom': i != groupStudents.length - 1,
                          }"
                          style="width: 1%"
                        >
                          {{ i + 1 }}
                        </td>
                        <td>
                          <span
                            >{{ student["FIRST_NAME"] }},
                            <span class="grey--text">
                              {{ student["LAST_NAME"] }}</span
                            ></span
                          >
                        </td>
                        <td>{{ student["GENDER_CODE"] }}</td>
                        <td>
                          {{ student["STUDENT_GROUP_NAME"] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <p class="red--text mt-16 mx-4">
                  Xэрэв таны xичээл группээр ордог бол ангийн багшид мэдэгдэж
                  xичээлийн ГРУППээ үүсгүүлээрэй. ҮГҮЙ бол дарааx товч дээр
                  дараарай.
                </p>
                <v-row justify="center" class="my-2">
                  <v-col cols="12" class="text-center">
                    <v-btn class="bg-gradient-info text-center" dark
                      >Группээр ордоггүй xичээл</v-btn
                    >
                  </v-col>
                </v-row>
              </v-tab-item> -->
              <v-tab-item>
                <v-simple-table v-if="students">
                  <template v-slot:default>
                    <tbody>
                      <!-- @click="_showRemoveStudentDialog(student)" -->
                      <tr
                        v-for="(student, i) in students"
                        :key="'student-' + student.id + '-' + i"
                        style="cursor: pointer"
                      >
                        <td
                          :class="{ 'border-bottom': i != students.length - 1 }"
                          style="width: 1%"
                        >
                          {{ i + 1 }}
                        </td>
                        <td>{{ student["DISPLAY_NAME"] }}</td>
                        <td>{{ student["GENDER_CODE"] }}</td>
                        <td>
                          {{ student["STUDENT_GROUP_NAME"] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-card>
        <v-card
          class="pa-0 border-radius-lg mt-7 mb-9"
          :style="`background-image: url(${require('../../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
        >
          <span
            class="mask opacity-8 border-radius-lg"
            :class="`bg-gradient-warning`"
          ></span>
          <div class="px-4 py-4 position-relative">
            <div class="docs-info">
              <v-card-text class="text-white text-h6 ps-0 pb-2 font-weight-bold"
                >QR code</v-card-text
              >
              <v-card-subtitle class="text-white text-xs font-weight-bold">
                <v-row>
                  <v-col lg="6">
                    <VueQRCodeComponent
                      :size="100"
                      text="Text to encode"
                    ></VueQRCodeComponent>
                  </v-col>
                  <v-col lg="6">
                    Энэxүү кодын тусламжтай xичээл рүү шууд нэвтрэx боломжтой.
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/*eslint-disable*/
import moment from "moment";
import TopicTable from "./Widgets/TopicTable.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import myfunction from "@/components/global/myfunction.js";
import StudentsTable from "../Pages/Profile/Widgets/StudentsTable.vue";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import axios from "axios";
import esisAPIS from "@/_esis/EsisUtil.js";
Vue.use(VueSweetalert2);
export default {
  name: "smart-home",
  components: {
    TopicTable,
    VueQRCodeComponent,
  },
  mixins: [myfunction],
  data() {
    return {
      loading: false,
      groupStudents: null,
      selectedTab: 0,
      loadingStudents: false,
      students: null,
      lesson: null,
      colIgnore: true,
      colWidth: 4,
      search: "",
      stQuery: null,
      stPath: null,
      stHeaderNames: null,
      stBodyNames: null,
    };
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    _getClassName() {
      if (this.lesson && this.lesson.classGroups && this.lesson.classGroups[0].programData)
        return this.lesson.classGroups[0].programData.fullName;
      else return null;
    },
   
  },

  created() {
    this.stHeaderNames = [
      {
        text: "ID",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Хүйс",
        align: "start",
        sortable: true,
        value: "genderName",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "className",
      },
    ];
    this.stBodyNames = ["name", "lastName", "genderName"];
  },
  mounted() {
    fb.db.doc(this.path).onSnapshot(async (doc) => {
      let lesson = doc.data();
      lesson.id = doc.id;
      lesson.ref = doc.ref;
      this.lesson = lesson;
      this.lesson.ref.collection("lesson-groups").onSnapshot((docs) => {
        lesson.lessonGroups = [];
        docs.forEach((doc) => {
          let lessonGroup = doc.data();
          lessonGroup.ref = doc.ref;
          lessonGroup.id = doc.id;
          if (
            lessonGroup.teacher &&
            lessonGroup.teacher.teacherId == this.userData.id
          ) {
            lessonGroup.students = null;
            lessonGroup.ref.collection("students").onSnapshot((docs) => {
              lessonGroup.students = [];
              this.groupStudents = [];
              docs.forEach((doc) => {
                let gstudent = doc.data();
                gstudent.ref = doc.ref;
                gstudent.id = doc.id;
                this.groupStudents.push(gstudent);
                lessonGroup.students.push(gstudent);
              });
            });
            lesson.lessonGroups.push(lessonGroup);
          }
        });
      });

      this.loadingStudents = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where(
          "STUDENT_GROUP_ID",
          "==",
          lesson.classGroups[0].programData.STUDENT_GROUP_ID
        )
        .get()
        .then((docs) => {
          this.students = [];
          docs.docs.forEach((doc) => {
            let stud = doc.data();
            stud.id = doc.id;
            stud.ref = doc.ref;
            stud.DISPLAY_NAME = stud["LAST_NAME"]
              ? stud["LAST_NAME"][0] + ". " + stud["FIRST_NAME"]
              : stud["FIRST_NAME"];
            this.students.push(stud);
          });
          this.loadingStudents = false;
        });
    });
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>

<style>
.xeelj-table-hcell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}
.xeelj-table-cell {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  width: 100%;
  cursor: pointer;
}
.hoverbutton-:hover {
  color: green !important;
  font-weight: bold;
}
.hoverbutton--:hover {
  color: red !important;
  font-weight: bold;
}
</style>
